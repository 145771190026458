
import React, { useEffect, useState } from "react";
import moment from 'moment';
import AppointmentsApi from "../../../services/AppointmentsApi";
import TouristAppointmentsApi from "../../../services/TouristAppointmentsApi";

export default function TouristAppointmentView(props) {
    const { touristId } = props
    const { getTouristAppointmnetById } = TouristAppointmentsApi()
    const [data, setData] = useState([])

    useEffect(() => {
        getTouristData();
    }, [])

    const getTouristData = () => {
        getTouristAppointmnetById(touristId)
            .then((res) => {
                setData(res)
            })
    }

    return (
        <div className="patientDataStyle">
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2" >PackageId</label>
                </div>
                <div className="width70">
                    {data.packageId}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2" >Package Name</label>
                </div>
                <div className="width70">
                    {data.packageName}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Package Duration</label>
                </div>
                <div className="width70">
                    {data.packageDuration}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Package Price</label>
                </div>
                <div className="width70">
                    {data.price}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Tourist Name</label>
                </div>
                <div className="width70">
                    {data.touristName}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Tourist City</label>
                </div>
                <div className="width70">
                    {data.touristCity}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Tourist Country</label>
                </div>
                <div className="width70">
                    {data.touristCountry}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Tourist Email</label>
                </div>
                <div className="width70">
                    {data.touristEmail}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Tourist Mobile</label>
                </div>
                <div className="width70">
                    {data.touristMobile}
                </div>
            </div>
            
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Journey - Start Date</label>
                </div>
                <div className="width70">
                    {moment(data.startDate).format("DD-MM-YYYY")}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Journey - End Date</label>
                </div>
                <div className="width70">
                    {moment(data.endDate).format("DD-MM-YYYY")}
                </div>
            </div>
            <div className="row">
                <div className="width30">
                    <label className="font_weight mx-2">Message</label>
                </div>
                <div className="width70">
                    {data.message}
                </div>
            </div>
        </div >
    )
}