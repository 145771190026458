import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import Loader from '../../Dashboard-card/Loader';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import ReactPaginate from 'react-paginate';
import TouristAppointmentsApi from '../../../services/TouristAppointmentsApi';
import { MainSelect } from '../../../mainComponent/mainSelect';

export default function PackageList() {
    const [packageList, setPackagesList] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [details, setDetails] = useState([]);
    const [packages, setPackages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1)
    const { getPackages, removePackage } = TouristAppointmentsApi();
    const navigate = useNavigate();
    const pageSize = 10;
    const paginationRef = useRef(currentPage);
    const packageRef = useRef(selectedPackage);

    useEffect(() => {
        getAllPackages();
    }, [])

    const handleDeleteShow = (details) => {
        setDetails(details)
        setShowDelete(true)
    }

    const handleDeleteClose = () => setShowDelete(false)

    function getAllPackages(selectedPackage, currentPage) {
        setIsLoading(true);
        const data = {
            page: currentPage,
            pageSize: pageSize,
            selectedPackage: selectedPackage
        }

        getPackages(data)
            .then((result) => {
                if (result) {
                    setPackagesList(result.packageData)
                    setPackages(result.packageData)
                    setTotalPages(result.touristListPages)
                } else {
                    return null
                }
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function deletePackage(details) {
        const id = details._id;
        removePackage(id)
            .then(() => {
                getAllPackages()
                handleDeleteClose()
            })
    }

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1;
        setCurrentPage(data.selected + 1)
        getAllPackages(selectedPackage, data.selected + 1);
    }

    // const handlePackageChange = (e) => {
    //     packageRef.current = e.target.value;
    //     setSelectedPackage(e.target.value);
    //     getAllPackages(e.target.value, currentPage)
    // };

    const handleEditClick = (e, details) => {
        e.preventDefault()
        navigate(`update/${details._id}`)
    }

    const handleViewClick = (e, details) => {
        e.preventDefault()
        navigate(`package/${details._id}`)
    }

    return (
        <div>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {/* <div className="white-box mb-2">
                        <div className='row' align='left'>
                            <div className='mr-4'>
                                <label className='mb-2 font_weight' value="lab">Find Appointments by Package-Name</label>
                                <div className='mt-2'>
                                    <MainSelect value={selectedPackage} onChange={handlePackageChange}>
                                        <option value="">Select Package-Name</option>
                                        {packages && packages.map((item, index) => (
                                            <option key={index} value={item.Package_name} className="form-control">{item.Package_name}</option>
                                        ))}
                                    </MainSelect>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {packageList && packageList.length > 0 ?
                        <>
                            <div className='row'>
                                <>
                                    {packageList && packageList.map((details, i) => {
                                        return (
                                            <div className="col-md-4 mb-4" key={i}>
                                                <div className="mainCards">
                                                    <span className='cardSpan'>
                                                        <i className='icon-user color' />
                                                        <b className='fontSize'>{details.Package_name}</b>
                                                    </span>
                                                    <span className='cardSpan'>
                                                        <i className='icon-money color' />
                                                        Price - {details.package_price} /-
                                                    </span>
                                                    <span className='cardSpan'>
                                                        <i className='mt-2 pe-7s-date color' />
                                                        Duration - {details.package_duration} Days
                                                    </span>
                                                    <div className="row justify-end top_border">
                                                        <div className="mt-3">
                                                            <Link onClick={(e) => handleViewClick(e, details)}>
                                                                <MainButtonInput>View Full Package</MainButtonInput>
                                                            </Link>
                                                        </div>
                                                        <div className="mt-3 ml-2">
                                                            <Link onClick={(e) => handleEditClick(e, details)}>
                                                                <MainButtonInput>Edit</MainButtonInput>
                                                            </Link>
                                                        </div>
                                                        <div className='mt-3 ml-2'>
                                                            <Link to="#" onClick={() => handleDeleteShow(details)}>
                                                                <SecondaryButtonInput>Delete</SecondaryButtonInput>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            </div>
                            <div className='mt-4'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    forcePage={currentPage - 1}
                                />
                            </div>
                        </> : <div className="clinicHistory font_weight" align='center' >Add your packages here</div>
                    }
                </>
            }

            <Modal show={showDelete} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor" >
                        You want to delete this package.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='appColor' variant="default " onClick={() => deletePackage(details)}>
                        Yes
                    </Button>
                    <Button variant="default" className='borderStyle' onClick={handleDeleteClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <Outlet />
        </div >

    )
}