import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import { Modal} from "react-bootstrap";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Loader from '../Loader';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Drawer, IconButton, useMediaQuery } from '@mui/material';
import TouristAppointmentView from './TouristAppointmentView';
import { MainSelect } from '../../../mainComponent/mainSelect';
import TouristAppointmentsApi from '../../../services/TouristAppointmentsApi';

export default function TouristAppointment() {
    const [touristList, setTouristList] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [touristId, setTouristId] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [activeModal, setActiveModal] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showData, setShowData] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [packages, setPackages] = useState([]);
    const { touristAppointmentsDetails, getPackages } = TouristAppointmentsApi()
    const isMobile = useMediaQuery('(max-width:768px)')
    const pageSize = 10;
    const paginationRef = useRef(currentPage);
    const packageRef = useRef(selectedPackage);

    useEffect(() => {
        getPatientDetails(selectedPackage, currentPage);
        getAllPackages()
    }, []);

    function getPatientDetails(selectedPackage, currentPage) {
        setIsLoading(true);
        const data = {
            page: currentPage,
            pageSize: pageSize,
            selectedPackage: selectedPackage
        }
        touristAppointmentsDetails(data)
            .then((result) => {
                setTouristList(result.data);
                setTotalPages(result.touristListPages)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getAllPackages = () => {
        getPackages()
            .then((res) => {
                setPackages(res.packageData)
            })
    }

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1;
        setCurrentPage(data.selected + 1)
        getPatientDetails(selectedPackage, data.selected + 1);
    }

    const handlePackageChange = (e) => {
        packageRef.current = e.target.value;
        setSelectedPackage(e.target.value);
        getPatientDetails(e.target.value, currentPage)
    };

    const openDrawerWithModal = (modalType, id) => {
        setTouristId(id)
        setActiveModal(modalType);
        setDrawerOpen(true);
    };

    const handleDataShow = (id) => {
        setTouristId(id)
        setShowData(true)
    }

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleDataClose = () => {
        setShowData(false)
        setDrawerOpen(false)
    }

    const renderModalContent = () => {
        switch (activeModal) {
            case 'tourismview':
                return <TouristAppointmentView
                    touristId={touristId}
                    onSubmit={handleDataClose}
                />
            default:
                return null;
        }
    };

    return (
        <>
            {isLoading === true ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    <div className="white-box mb-2">
                        <div className='row' align='left'>
                            <div className='mr-4'>
                                <label className='mb-2 font_weight' value="lab">Find Appointments by Package-Name</label>
                                <div className='mt-2'>
                                    <MainSelect value={selectedPackage} onChange={handlePackageChange}>
                                        <option value="">Select Package-Name</option>
                                        {packages && packages.map((item, index) => (
                                            <option key={index} value={item.Package_name} className="form-control">{item.Package_name}</option>
                                        ))}
                                    </MainSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                    {touristList && touristList.length > 0 ?
                        <>
                            <div className='row'>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="font_weight" align="left">Tourist Name</TableCell>
                                                <TableCell className="font_weight" align="left">Tourist Email</TableCell>
                                                <TableCell className="font_weight" align="left">Package Name</TableCell>
                                                <TableCell className="font_weight" align="left">Journey Start Date</TableCell>
                                                <TableCell className="font_weight" align="left">View</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {touristList && touristList.map((item, index) => {
                                                return (
                                                    <TableRow key={item._id}>
                                                        <TableCell align="left">
                                                            {item.touristName}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {item.touristEmail}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {item.packageName}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {moment(item.startDate).format("DD-MM-YYYY")}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Link onClick={() => isMobile ? openDrawerWithModal('tourismview', item._id) : handleDataShow(item._id)}>
                                                                <VisibilityIcon style={{ fontSize: 20 }} />
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className='mt-4'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    forcePage={currentPage - 1}
                                />
                            </div>
                        </> :
                        <div className="clinicHistory font_weight" >Appointments are not Available</div>
                    }

                    {isMobile ?
                        <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle}>
                            <div className='p-4'>
                                <div align='right'>
                                    <IconButton onClick={handleDrawerToggle}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                <div className='p-2'>
                                    {renderModalContent()}
                                </div>
                            </div>
                        </Drawer>
                        :
                        <Modal show={showData} onHide={handleDataClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Tourist Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <TouristAppointmentView
                                    touristId={touristId}
                                    onSubmit={handleDataClose}
                                />
                            </Modal.Body>
                        </Modal>
                    }
                </>
            }
        </>
    )
}