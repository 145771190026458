import { useEffect, useState, useRef } from "react";
import AppointmentsApi from "../../../services/AppointmentsApi";
import moment from 'moment';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { Link } from "react-router-dom";
import { FaClinicMedical } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { SecondaryButtonInput } from "../../../mainComponent/secondaryButtonInput";
import AuthApi from "../../../services/AuthApi";
import { MainSelect } from "../../../mainComponent/mainSelect";
import { THEME_COLOR } from '../../../config'

export default function CompletedAppointment(props) {
    const { doctorId } = props;
    const [patientHistoryData, setPatientHistoryData] = useState(null)
    const [clinics, setClinics] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const { getAppointmentsDetails, downloadPrescription } = AppointmentsApi()
    const [isLoading, setIsLoading] = useState(true);
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null)
    const { getDrInfo } = AuthApi();
    const clinicRef = useRef(selectedClinic);
    const paginationRef = useRef(currentPage);
    const doctorRef = useRef(selectedDoctor);

    useEffect(() => {
        getPatientHistory(selectedClinic, currentPage, selectedDoctor);
        getAllClinics()
    }, [])

    const pageSize = 6;
    function getPatientHistory(selectedClinic, currentPage, selectedDoctor) {
        const data = {
            page: currentPage,
            pageSize: pageSize,
            status: "Completed",
            selectedClinic: selectedClinic,
            selectedDoctor: selectedDoctor
        }
        setIsLoading(true);
        getAppointmentsDetails(doctorId, data)
            .then((result) => {
                setPatientHistoryData(result.pageIndex);
                setTotalPages(result.totalPages);
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleClinicChange = (e) => {
        clinicRef.current = e.target.value;
        setSelectedClinic(e.target.value);
        getPatientHistory(e.target.value, currentPage, selectedDoctor)
    };

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1;
        setCurrentPage(data.selected + 1 )
        getPatientHistory( selectedClinic, data.selected + 1, selectedDoctor)
    }

    const handleDoctorChange = (e) => {
        doctorRef.current = e.target.value;
        const doctorId = e.target.value;
        setSelectedDoctor(doctorId);
        getPatientHistory(selectedClinic, currentPage, doctorId)
    };

    const getAllClinics = () => {
        getDrInfo({ doctorId })
            .then((jsonRes) => {
                const clinicsData = jsonRes.result[0]["clinicList"]
                const doctorsData = jsonRes.result[0]["doctorList"]
                doctorsData.push(jsonRes.result[0])
                setClinics(clinicsData)
                setDoctors(doctorsData)
            })
    }

    const downloadPdf = (details) => {
        const reportId = details.medicalReportId
        downloadPrescription(reportId)
            .then((result) => {
                let alink = document.createElement('a');
                alink.href = result;
                alink.setAttribute("target", "_blank")
                alink.download = 'Prescription.pdf';
                alink.click();
            })
    }

    return (
        <>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    <div className="white-box mb-2">
                        <div className='row' align='left'>
                            <div className='mr-4'>
                                <label className='mb-2 font_weight' value="lab">Find Appointments by Clinic</label>
                                <div className='mt-2'>
                                    <MainSelect value={selectedClinic} onChange={handleClinicChange}>
                                        <option value="">Select Clinic</option>
                                        {clinics && clinics.map((item, index) => (
                                            <option key={index} value={item.clinicName} className="form-control">{item.clinicName}</option>
                                        ))}
                                    </MainSelect>
                                </div>
                            </div>
                            <div className='mr-4'>
                                <label className='mb-2 font_weight' value="lab">Find Appointments by doctor</label>
                                <div className='mt-2'>
                                    <MainSelect value={selectedDoctor} onChange={handleDoctorChange}>
                                        <option value="">Select doctor</option>
                                        {doctors && doctors.map((item, index) => (
                                            <option key={index} value={item._id} className="form-control">{item.name}</option>
                                        ))}
                                    </MainSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                    {patientHistoryData && patientHistoryData.length > 0 ?
                        <>
                            <div className='row'>
                                {patientHistoryData && patientHistoryData.map((details, i) => {
                                    return (
                                        <>
                                            {details && !details.dependentId ?
                                                <div key={i} className="col-md-4">
                                                    <div className="cardDiv">
                                                        <span className='cardSpan'>
                                                            <i className='icon-user color patientListIcon' />
                                                            <span className='patientName'>
                                                                {details['patientDetails'][0].name}
                                                            </span>
                                                        </span>
                                                        <span className='cardSpan'>
                                                            <i className='icon-mobile-1 color patientListIcon' />
                                                            {details['patientDetails'][0].mobile}
                                                        </span>
                                                        <span className='cardSpan '>
                                                            <i className='color patientListIcon ml-1 mr-2'><FaClinicMedical /></i>
                                                            {details['clinicList'].clinicName}
                                                        </span>
                                                        <span className='cardSpan time'>
                                                            <i className='pe-7s-date m-1 color patientListIcon' />
                                                            <span className='slotTime'>
                                                                {moment(details.selectedDate).format('YYYY-MM-DD').toString()},{details.slotTime}
                                                            </span>
                                                        </span>
                                                        <span className='cardSpan'>
                                                            <AccessTimeRoundedIcon style={{ fontSize: 22, marginTop: 5, marginLeft: 2,marginRight: 2, color:  THEME_COLOR  }} />
                                                            {details.timeSlot} Min.
                                                        </span>

                                                        <div className="row justify-end top_border">
                                                            <div className="mt-3">
                                                                <Link to={`report/${details.medicalReportId}`}>
                                                                    <MainButtonInput>View Report</MainButtonInput>
                                                                </Link>
                                                            </div>
                                                            <div className='mt-3 ml-2'>
                                                                <SecondaryButtonInput onClick={() => downloadPdf(details)}>Print</SecondaryButtonInput>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div key={i} className="col-md-4 ">
                                                    <div className="cardDiv">
                                                        <div className='cardSpan row'>
                                                            <div align='left' className='width_70' >
                                                                <i className=' icon-user color patientListIcon' />
                                                                <span className=' patientName'>{details['dependentDetails'][0].name}</span>
                                                            </div>
                                                            <div className='width_10' align='right'>
                                                                <span className='dependent'>Dependent</span>
                                                            </div>
                                                        </div>
                                                        <span className='cardSpan'>
                                                            <i className='icon-mobile-1 color patientListIcon' />
                                                            {details['patientDetails'][0].mobile}
                                                        </span>
                                                        <span className='cardSpan '>
                                                            <i className='icon-hospital-1 color patientListIcon' />
                                                            {details['clinicList'].clinicName}
                                                        </span>
                                                        <span className='cardSpan time'>
                                                            <i className='pe-7s-date m-1 color patientListIcon' />
                                                            <span className='slotTime'>
                                                                {moment(details.selectedDate).format('YYYY-MM-DD').toString()},{details.slotTime}
                                                            </span>
                                                        </span>
                                                        <span className=' cardSpan'>
                                                            <AccessTimeRoundedIcon style={{ fontSize: 25, marginTop: 4, marginLeft: 2, color: { THEME_COLOR } }} />
                                                            {details.timeSlot} Min.
                                                        </span>
                                                        <div className="row justify-end top_border">
                                                            <div className="mt-3">
                                                                <Link to={`report/${details.medicalReportId}`}>
                                                                    <MainButtonInput>View</MainButtonInput>
                                                                </Link>
                                                            </div>
                                                            <div className='mt-3 ml-2'>
                                                                <SecondaryButtonInput onClick={() => downloadPdf(details)}>Print</SecondaryButtonInput>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )

                                })}
                            </div>
                            <div className="marginleft">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination "
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    forcePage={currentPage - 1}
                                />
                            </div>
                        </> :
                        <div className="clinicHistory font_weight" >Appointments are not available</div>
                    }

                </>
            }

        </>
    )
}