import React, { useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { MainNav } from '../../mainComponent/mainNav';
import { setHelperData } from "../../recoil/atom/setHelperData";
import { Wrapper } from '../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Button, Modal } from 'react-bootstrap';
import UserLinks from './partial/uselinks';
import AddLabWorkTable from './partial/Addlabworktable';
import GetLabWork from './partial/GetLabWork';
import AddWork from './modalbox/addworkmodal';
import AddLab from './modalbox/addlabmodal';
import { Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function AddLabWork() {
    const { doctorId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [labWorkModal, setLabWorkModal] = useState(false)
    const [labModal, setLabModal] = useState(false)
    const [workModal, setWorkModal] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeModal, setActiveModal] = useState(null);
    const isMobile = useMediaQuery('(max-width:768px)');

    const handleLabworkShow = () => setLabWorkModal(true);
    const handleLabworkClose = () => setLabWorkModal(false);

    const handleLabClose = () => setLabModal(false);
    const handleLabShow = () => setLabModal(true);

    const handleWorkClose = () => setWorkModal(false);
    const handleWorkShow = () => setWorkModal(true);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const openDrawerWithModal = (modalType) => {
        setActiveModal(modalType);
        setDrawerOpen(true);
    };

    const renderModalContent = () => {
        switch (activeModal) {
            case 'labWork':
                return <AddLabWorkTable doctorId={doctorId} onClick={onFormSubmit} />;
            case 'lab':
                return <AddLab onClick={onFormSubmit} />;
            case 'work':
                return <AddWork onClick={onFormSubmit} />;
            default:
                return null;
        }
    };

    const getDrawerTitle = () => {
        switch (activeModal) {
            case 'labWork':
                return 'Add Lab Work';
            case 'lab':
                return 'Add Lab';
            case 'work':
                return 'Add Work';
            default:
                return '';
        }
    };


    const onFormSubmit = () => {
        setDrawerOpen(false);
        handleLabClose();
        handleWorkClose();
        handleLabworkClose();
    };

    return (
        <Wrapper>
            <MainNav>
                <div className="clearfix row">
                    <div className="width50">
                        <ul className="clearfix">
                            <li className='float-none' style={{ fontSize: 'inherit' }}>Lab Work</li>
                        </ul>
                    </div>
                    <div className="width50 row justifyContent">
                        <Button
                            type="submit"
                            onClick={() => isMobile ? openDrawerWithModal('lab') : setLabModal(true)}
                            variant="default"
                            className='appColor  mr-3 btn_sub'>
                            Add Lab
                        </Button>
                        <Button
                            type="submit"
                            onClick={() => isMobile ?
                                openDrawerWithModal('work') :
                                setWorkModal(true)}
                            variant="default"
                            className='appColor btn_sub'>
                            Add Work
                        </Button>
                    </div>
                </div>
            </MainNav>

            <div className='row'>
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box">
                        <div className='m-4' align='right'>
                            <Button className="btn_1" type="submit"
                                onClick={() => isMobile ? openDrawerWithModal('labWork') : setLabWorkModal(true)}>Add Lab Work</Button>
                        </div>
                        <GetLabWork doctorId={doctorId} />
                    </div>
                </div>
            </div>

            {isMobile ? (
                <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle}>
                    <div className='p-4'>
                        <div className='drawerTitle underline' >
                            <Typography variant="h6">
                                {getDrawerTitle()}
                            </Typography>
                            <IconButton onClick={handleDrawerToggle} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-2'>
                            {renderModalContent()}
                        </div>
                    </div>
                </Drawer>
            )
                : (
                    <>
                        <Modal show={labWorkModal} onHide={handleLabworkClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Lab Work</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AddLabWorkTable doctorId={doctorId} onClick={onFormSubmit} />
                            </Modal.Body>
                        </Modal>

                        <Modal show={labModal} onHide={handleLabClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Lab</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AddLab onClick={onFormSubmit} />
                            </Modal.Body>
                        </Modal>

                        <Modal show={workModal} onHide={handleWorkClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Work</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AddWork onClick={onFormSubmit} />
                            </Modal.Body>
                        </Modal>
                    </>
                )}
        </Wrapper>
    )
}
