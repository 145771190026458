import React, { useState, useEffect } from 'react';
import { Outlet, useParams, Link } from "react-router-dom";
import UserLinks from '../partial/uselinks';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { useRecoilState } from "recoil";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { MainNav } from '../../../mainComponent/mainNav';
import TouristAppointmentsApi from '../../../services/TouristAppointmentsApi';
import { Modal, Button } from "react-bootstrap";

export default function ViewFullPackage() {
    const { doctorId, packageId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [packageData, setPackageData] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { getPackageById } = TouristAppointmentsApi()

    useEffect(() => {
        getPakage();
    }, [])

    const openModal = (url) => {
        setSelectedImage(url);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };
    
    const getPakage = () => {
        getPackageById(packageId)
            .then((packageResponse) => {
                setPackageData(packageResponse)
                if (packageResponse["package_images"]) {
                    setDocuments(packageResponse["package_images"])
                }
            })
    };

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix ">
                    <li>
                        <Link to={`/tourism/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li
                        className='float-none'
                        style={{ fontSize: 'inherit' }} >
                        Package Details
                    </li>
                </ul>
            </MainNav>

            <div className="row">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84 common_box'>
                    <div className='white-box'>
                        <h2 align='center' className="mb-2">{packageData.Package_name}</h2>
                        <div className="p-2" align="left">
                            <div className="mb-2 bottomBorder"><span className="patientModal">Package Price -</span> {packageData.package_price}</div>
                            <h6 className="patientModal"><span className="bullet"></span>Description</h6>
                            <div className="mb-2"> {packageData.package_excerpt}</div>
                            <h6 className="patientModal"><span className="bullet"></span>Activities </h6>
                            <div>
                                {packageData && Array.isArray(packageData.package_activities) && packageData.package_activities.map((activity, index) => {
                                    return (
                                        <div key={index}>
                                            <h6>Day {index + 1}</h6>
                                            <div className="mb-2">
                                                <span className="patientModal">Activities - </span>{activity.activity}
                                            </div>
                                            <div className="mb-2">
                                                <span className="patientModal">Description - </span>{activity.description}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <h6 className="patientModal"><span className="bullet"></span>Photos </h6>
                            <div>
                                {documents && documents.length > 0 && (
                                    <div className='row'>
                                        {documents && documents.map((url, index) => (
                                            <div key={index}>
                                                <img
                                                    src={url}
                                                    alt={`Uploaded file ${index + 1}`}
                                                    className='uploadedimg'
                                                    onClick={() => openModal(url)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <Modal show={isModalOpen} onHide={closeModal}>
                    <Modal.Header closeButton onClick={(e) => e.stopPropagation()}>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={selectedImage} alt="Selected Image" className="modal-image" />
                    </Modal.Body>
                </Modal>
            )}
            <Outlet />
        </Wrapper>

    )
}