import React, { useState } from 'react';
import { useParams, Outlet, useNavigate } from 'react-router-dom';
import { MainNav } from '../mainComponent/mainNav';
import { Wrapper } from '../mainComponent/Wrapper';
import UserLinks from '../doctor/Dashboard-card/partial/uselinks';
import { setHelperData } from '../recoil/atom/setHelperData';
import { useRecoilState } from "recoil";
import PatientList from '../doctor/Dashboard-card/PatientList';
import { Button } from 'react-bootstrap';
import { MainTabs } from '../mainComponent/mainTabs';
import { TabPanel } from '../common/tabpanel';

export default function Patient() {
    const { doctorId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const navigate = useNavigate();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const BookAppointment = () => {
        navigate(`bookappointment`)
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix ">
                    {/* <li>
                        <Link to={`/appointments/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li> */}
                    <li
                        className='float-none'
                        style={{ fontSize: 'inherit' }} >
                        Appointments
                    </li>
                    <li>
                        <div align='right'>
                            <Button
                                className='appColor btn_sub'
                                type="submit"
                                onClick={BookAppointment}>
                                Book Apppointment
                            </Button>
                        </div>
                        {/* <Link onClick={() => setActive(!active)} >
                            <Icon className="addiconbutton" style={{ fontSize: 50 }}>add</Icon>
                        </Link> */}
                    </li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84 common_box'>
                    <MainTabs
                        value={value}
                        onChange={handleChange}
                        label="Ongoing Appointments"
                        // label1="Tourist Appointments"
                    >
                    </MainTabs>

                    <TabPanel value={value} index={0}>
                        <PatientList onChange={() => setValue(0)} doctorId={doctorId} />
                    </TabPanel>

                    {/* <TabPanel value={value} index={1}>
                        <TouristAppointmentList doctorId={doctorId} />
                    </TabPanel> */}
                </div>
            </div>
            <Outlet />
        </Wrapper>
    )
} 