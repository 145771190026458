import React, {useState} from 'react';
import { Outlet, useParams, useNavigate } from "react-router-dom";
import UserLinks from '../partial/uselinks';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { useRecoilState } from "recoil";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { MainNav } from '../../../mainComponent/mainNav';
import TouristAppointment from './TouristAppointments';
import { MainTabs } from '../../../mainComponent/mainTabs';
import { TabPanel } from '../../../common/tabpanel';
import PackageList from './PackageList';
import { Button } from 'react-bootstrap';

export default function Packages() {
    const { doctorId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const AddPackage = () => {
        navigate(`addpackage`)
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix ">
                    <li
                        className='float-none'
                        style={{ fontSize: 'inherit' }} >
                        Tourist Appointments
                    </li>
                    <li>
                        <div align='right'>
                            <Button
                                className='appColor btn_sub'
                                type="submit"
                                onClick={AddPackage}>
                                Add Package
                            </Button>
                        </div>
                    </li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84 common_box'>
                    <MainTabs
                        value={value}
                        onChange={handleChange}
                        label="Tourism Packages"
                        label1="Tourist Appointments"
                    >
                    </MainTabs>

                    <TabPanel value={value} index={0}>
                        <PackageList onChange={() => setValue(0)} doctorId={doctorId} />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <TouristAppointment />
                    </TabPanel>
                </div>
            </div>
            <Outlet />
        </Wrapper>

    )
}