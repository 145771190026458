import React, { useEffect, useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import { toast } from 'react-toastify';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { MainSelect } from '../../../mainComponent/mainSelect'
import DatePicker from 'react-date-picker';
import { Autocomplete, Box, TextField } from '@mui/material';
import AuthApi from '../../../services/AuthApi';
import InventoryApi from '../../../services/InventoryApi';
import { useRecoilState } from 'recoil';
import { setClinicId } from '../../../recoil/atom/setClinicId';
import { THEME_COLOR } from '../../../config';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import { setInventoryDetails } from '../../../recoil/atom/setInventoryData';
import inventory from '../../../data/inventory.json';

export default function AddInventoryModalBox(props) {
    const { doctorId } = props;
    const { addInward,
        fetchInventoryItem,
        fetchManufacturerName,
        fetchCategory,
        fetchSubCategory,
        insertInventoryItem,
        insertManufacturerName,
        insertCategory,
        insertSubCategory
    } = InventoryApi();
    const { getDrInfo } = AuthApi()
    const [addInwardData, setInwardData] = useState({})
    const [expiryDate, setExpiryDate] = useState();
    const [inventoryData, setInventoryData] = useRecoilState(setInventoryDetails)
    const [inwardDate, setInwardDate] = useState();
    const [clinicId, setClinicid] = useRecoilState(setClinicId);
    const [clinicList, setClinicList] = useState([]);
    const [saveClinic, setSaveClinic] = useState([]);
    const [inventoryItem, setInventoryItem] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [selectedcategory, setSelectedcategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [manufacturer, setManufacturer] = useState([]);
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [showInput, setShowInput] = useState(false);
    const [manufacturerInput, setManufacturerInput] = useState(false);
    const [categorytoggle, setCategorytoggle] = useState(false);
    const [subcategorytoggle, setSubcategorytoggle] = useState(false);
    const [selectedQuantity, setSelectedQuantity] = useState('')
    useEffect(() => {
        getDrDetails();
        getData();
    }, [])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInwardData({ ...addInwardData, [name]: value })
    }

    const getDrDetails = () => {
        getDrInfo({ doctorId })
            .then((res) => {
                const clinicList = res.result[0]['clinicList']
                if (clinicList) {
                    setClinicList(clinicList)
                } else {
                    setClinicList('')
                }
            })
    }

    const getData = () => {
        fetchInventoryItem()
            .then((res) => {
                setInventoryItem(res)
            })
        fetchManufacturerName()
            .then((res) => {
                setManufacturer(res)
            })
        fetchCategory()
            .then((res) => {
                setCategory(res)
            })
        fetchSubCategory()
            .then((res) => {
                setSubCategory(res)
            })
    }

    const toggleInventoryItem = () => {
        setShowInput(!showInput);
    }

    const toggleManufacturer = () => {
        setManufacturerInput(!manufacturerInput)
    }
    const toggleCategory = () => {
        setCategorytoggle(!categorytoggle)
    }
    const toggleSubCategory = () => {
        setSubcategorytoggle(!subcategorytoggle)
    }

    const handleClinics = (event, selectedValue) => {
        event.preventDefault()
        setSaveClinic(selectedValue)
    }

    const handleItemName = (e, selectedData) => {
        e.preventDefault()
        setSelectedItem(selectedData)
    }

    const handleManufacturerName = (e, selectedData) => {
        e.preventDefault()
        setSelectedManufacturer(selectedData)
    }

    const handleCategory = (e, selectedData) => {
        e.preventDefault()
        setSelectedcategory(selectedData)
    }
    const handleSubcategory = (e, selectedData) => {
        e.preventDefault()
        setSelectedSubcategory(selectedData)
    }

    const handleSelectData = (e) => {
        e.preventDefault();
        setSelectedQuantity(e.target.value)
    }

    const saveData = (e) => {
        e.preventDefault();
        const bodyData = {
            doctorId: doctorId,
            clinicId: saveClinic._id,
            itemName: selectedItem.itemName ? selectedItem.itemName : addInwardData.itemName,
            manufacturerName: selectedManufacturer.manufacturerName ? selectedManufacturer.manufacturerName : addInwardData.manufacturerName,
            category: selectedcategory.category ? selectedcategory.category : addInwardData.category,
            subCategory: selectedSubcategory.subCategory ? selectedSubcategory.subCategory : addInwardData.subCategory,
            expiryDate: expiryDate,
            inwardDate: inwardDate,
            quantity: addInwardData.quantity,
            selectedQuantity: selectedQuantity,
            cost: addInwardData.cost,
            spoc: addInwardData.spoc,
            clinicName: saveClinic.clinicName
        }
        addInward(doctorId, saveClinic._id, bodyData)
            .then((response) => {
                setInventoryData(inventoryData.concat(response))
            })
        if (addInwardData.itemName) {
            const insertItem = {
                itemName: addInwardData.itemName
            }
            insertInventoryItem(insertItem)
        }
        if (addInwardData.manufacturerName) {
            const insertManufacturer = {
                manufacturerName: addInwardData.manufacturerName
            }
            insertManufacturerName(insertManufacturer)
        }
        if (addInwardData.category) {
            const category = {
                category: addInwardData.category
            }
            insertCategory(category)
        }
        if (addInwardData.subCategory) {
            const subCategory = {
                subCategory: addInwardData.subCategory
            }
            insertSubCategory(subCategory)
        }
        toast.success("Saved Successfully!")
        setClinicid(saveClinic._id)
        props.onSubmit()
    }

    const addExpiryDate = (date) => {
        setExpiryDate(date)
    }

    const addInwardDate = (date) => {
        setInwardDate(date)
    }

    return (
        <>
            <>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <label className='left font_weight' >Item Name</label>
                        <div align='right'>
                            <div className='hover-container'>
                                <>
                                    <Link onClick={toggleInventoryItem} >
                                        <AddCircleIcon style={{ fontSize: 20, color: THEME_COLOR }} />
                                    </Link>
                                </>
                                <div className="hover-text ">
                                    Add Item.
                                </div>
                            </div>
                        </div>
                        {showInput ? (
                            <input
                                type="text"
                                className="form-control mb-2"
                                name="itemName"
                                placeholder="Item Name"
                                onChange={handleChange}
                            />
                        ) : (
                            <Autocomplete
                                id={inventoryItem._id}
                                disablePortal={true}
                                disableClearable
                                disableCloseOnSelect
                                className='mb-2'
                                onChange={handleItemName}
                                getOptionLabel={(inventoryItem) => `${inventoryItem.itemName}`}
                                options={inventoryItem}
                                renderInput={(params) =>
                                (<TextField {...params}
                                    label=" Item Name"
                                />)}
                            />
                        )}

                    </div>

                    <div className="col-md-6">
                        <label className='left font_weight' >Cost</label>
                        <input
                            type="text"
                            value={addInwardData.cost}
                            className="form-control mb-2"
                            name="cost"
                            placeholder="Cost"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <label className='left font_weight'>Manufacturer Name</label>
                        <div align='right'>
                            <div className='hover-container'>
                                <>
                                    <Link onClick={toggleManufacturer} >
                                        <AddCircleIcon style={{ fontSize: 20, color: THEME_COLOR }} />
                                    </Link>
                                </>
                                <div className="hover-text ">
                                    Add Manufacturer.
                                </div>
                            </div>
                        </div>
                        {manufacturerInput ? (
                            <input
                                type="text"
                                className="form-control mb-2"
                                value={addInwardData.manufacturerName}
                                name="manufacturerName"
                                placeholder="Manufacturer Name"
                                onChange={handleChange}
                            />
                        ) : (
                            <Autocomplete
                                id={manufacturer._id}
                                disablePortal={true}
                                disableClearable
                                disableCloseOnSelect
                                className='mb-2'
                                onChange={handleManufacturerName}
                                getOptionLabel={(manufacturer) => `${manufacturer.manufacturerName}`}
                                options={manufacturer}
                                renderInput={(params) =>
                                (<TextField {...params}
                                    label=" Manufacturer Name"

                                />)}
                            />)
                        }
                    </div>

                    <div className="col-md-6">
                        <div className='row'>
                            <div className='width_45 '>
                                <label className='left font_weight' >Quantity</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    value={addInwardData.quantity}
                                    name="quantity"
                                    placeholder="Quantity"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="width_45 margin_top_25  ml-2">
                                <MainSelect
                                    value={selectedQuantity}
                                    onChange={handleSelectData}
                                >
                                    <option value="">select</option>
                                    {inventory && inventory.map((item, index) => (
                                        <option key={index}
                                            value={item.name}
                                            className="form-control">
                                            {item.name}
                                        </option>
                                    ))}
                                </MainSelect>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row  mt-2">
                    <div className="col-md-6">
                        <label className='left font_weight'>Category</label>
                        <div align='right'>
                            <div className='hover-container'>
                                <>
                                    <Link onClick={toggleCategory} >
                                        <AddCircleIcon style={{ fontSize: 20, color: THEME_COLOR }} />
                                    </Link>
                                </>
                                <div className="hover-text ">
                                    Add Category.
                                </div>
                            </div>
                        </div>
                        {categorytoggle ? (
                            <input
                                type="text"
                                className="form-control mb-2"
                                value={addInwardData.category}
                                name="category"
                                placeholder="Category"
                                onChange={handleChange}
                            />
                        ) : (
                            <Autocomplete
                                id={category._id}
                                disablePortal={true}
                                className='mb-2'
                                disableClearable
                                disableCloseOnSelect
                                onChange={handleCategory}
                                getOptionLabel={(category) => `${category.category}`}
                                options={category}
                                renderInput={(params) =>
                                (<TextField {...params}
                                    label="Category"
                                />)}
                            />
                        )}
                    </div>

                    <div className="col-md-6">
                        <label className='left font_weight'>SPOC</label>
                        <input
                            type="text"
                            value={addInwardData.spoc}
                            className="form-control mb-2"
                            name="spoc"
                            placeholder="Spoc"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row  mt-2">
                    <div className="col-md-6">
                        <label className='left font_weight'>Subcategory</label>
                        <div align='right'>
                            <div className='hover-container'>
                                <>
                                    <Link onClick={toggleSubCategory} >
                                        <AddCircleIcon style={{ fontSize: 20, color: THEME_COLOR }} />
                                    </Link>
                                </>
                                <div className="hover-text ">
                                    Add Subcategory.
                                </div>
                            </div>
                        </div>
                        {subcategorytoggle ? (
                            <input
                                type="text"
                                className="form-control mb-2"
                                value={addInwardData.subCategory}
                                name="subCategory"
                                placeholder="Subcategory"
                                onChange={handleChange}
                            />
                        ) : (
                            <Autocomplete
                                id={subCategory._id}
                                disablePortal={true}
                                disableClearable
                                className='mb-2'
                                disableCloseOnSelect
                                onChange={handleSubcategory}
                                getOptionLabel={(subCategory) => `${subCategory.subCategory}`}
                                options={subCategory}
                                renderInput={(params) =>
                                (<TextField {...params}
                                    label="Subcategory"
                                />)}
                            />

                        )}
                    </div>

                    <div className="col-md-6">
                        <label className="font_weight">Clinic List</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveClinic.clinicName}
                            onChange={handleClinics}
                            className='mb-2'
                            getOptionLabel={(data) => `${data.clinicName}`}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.clinicName}</Box>)}
                            options={clinicList}
                            noOptionsText={"Clinic not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Clinic Name"
                                />
                            }
                        />
                    </div>
                </div>
                <div className="row  mt-2">
                    <div className="col-md-6">
                        <div className='mt-2'>
                            <label className="font_weight">Inward Date</label>
                            <div className="form-group">
                                <DatePicker
                                    className="datepicker"
                                    onChange={addInwardDate}
                                    value={inwardDate}
                                    clearIcon={null}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='mt-2'>
                            <label className="font_weight">Expiry Date</label>
                            <div className="form-group">
                                <DatePicker
                                    className="datepicker"
                                    onChange={addExpiryDate}
                                    value={expiryDate}
                                    clearIcon={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row float-right">
                    <Toaster />
                </div>
            </>
            <div className=" float-right mt-3 mr-3">
                <MainButtonInput onClick={saveData}> Save</MainButtonInput>
            </div>
        </>
    )

}
