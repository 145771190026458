import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Topbar = (props) => {
    return (
        <div>
            <div
                style={{ padding: 4, backgroundColor: '#d7eaf5' }}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography
                    className="m-2"
                    // align='left'
                    style={{ color: 'black' }}>
                    <div className="row">
                        <div className="col-md-6" align='left'>
                            <h4  >{props.icon}  {props.title}
                                {props.fees}
                            </h4>
                            <div>{props.address}</div>
                        </div>
                        <div className="col-md-6 walkingSlot">{props.slot}</div>
                    </div>
                </Typography>
                <Typography
                    className="ml-4"
                    align='left'
                    style={{ color: 'black' }}>
                    {props.address}
                </Typography>
            </div>
            <div>
                <Typography>
                    {props.children}
                </Typography>
            </div>
        </div>
    )
}
export { Topbar }