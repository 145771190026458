import React, { useState, useEffect } from "react";
import { SetSession } from "../Session/setSession";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { MainButtonInput } from "../../../../mainComponent/mainButtonInput";
import { SearchClinic } from "./searchClinic";
import AuthApi from "../../../../services/AuthApi";
import ReactPaginate from "react-paginate";
import Loader from "../../../Dashboard-card/Loader";
import { setDoctorClinic } from "../../../../recoil/atom/setDoctorClinic";
import { SecondaryButtonInput } from "../../../../mainComponent/secondaryButtonInput";
import { EditClinic } from "./EditClinic";
import ClinicApi from "../../../../services/ClinicApi";
import Toaster from "../../../Toaster";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Drawer, IconButton, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function AddDoctorClinicInfo(props) {
    const { doctorId, newDoctorId } = props;
    const [activeModal, setActiveModal] = useState();
    const [clinicList, setClinicList] = useRecoilState(setDoctorClinic);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState();
    const [showSearch, setShowSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showDelete, setShowDelete] = useState(false);
    const [updateClinicId, setUpdateClinicId] = useState([]);
    const [showClinic, setShowClinic] = useState(false);
    const [Item, setItem] = useState([]);
    const isMobile = useMediaQuery('(max-width:  768px)')

    const { getDrInfo } = AuthApi();
    const { clinicDelete } = ClinicApi();

    useEffect(() => {
        getAllClinics(currentPage);
    }, [])

    const pageSize = 5;
    const getAllClinics = (currentPage) => {
        setIsLoading(true);
        getDrInfo({ doctorId }, currentPage, pageSize)
            .then((jsonRes) => {
                const clinicData = jsonRes['clinicList']
                setClinicList(clinicData)
                setTotalPages(jsonRes.clinicListPages)
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const deleteclinic = (Item) => {
        clinicDelete(doctorId, Item._id)
        handleDeleteClose()
        setClinicList(clinicList.filter(clinic => clinic._id !== Item._id))
        toast.success("Delete Successfully!");
    }

    const handleSearchClose = () => {
        setShowSearch(false)
    }

    const handleSearchShow = () => {
        setShowSearch(true)
    }

    const handleClinicClose = () => {
        setShowClinic(false)
    }

    const handleClinicShow = (item) => {
        setUpdateClinicId(item)
        setShowClinic(true)
        setShowSearch(false)
    }
    const handleDeleteShow = (item) => {
        setItem(item)
        setShowDelete(true)
    }

    const handleDeleteClose = () => {
        setShowDelete(false)
    }

    const sessionClose = () => {
        setActiveModal(false);
    };

    const sessionShow = (item) => {
        setUpdateClinicId(item)
        setActiveModal(true);
    }

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }


    return (
        <div >
            <div className="modalbtn">

                {isMobile ? (
                    <Drawer anchor="bottom" open={showSearch} onClose={handleSearchClose}>
                        <div className='drawerTitle underline' >
                            <Typography variant="h6">Search Clinic</Typography>
                            <IconButton onClick={handleSearchClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-4'>
                            <SearchClinic
                                doctorId={doctorId}
                                onSubmit={handleSearchClose} />
                        </div>
                    </Drawer>
                ) : (
                    <Modal show={showSearch} onHide={handleSearchClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Search Clinic</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SearchClinic
                                doctorId={doctorId}
                                onSubmit={handleSearchClose} />
                        </Modal.Body>
                    </Modal>
                )}
            </div>
            {isLoading ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {clinicList && clinicList.length > 0 ?
                        <>
                            {clinicList && clinicList.map((item, index) => (
                                <div key={item._id}>
                                    <div className='row underline mb-3'>
                                        <figure className="width_20" >
                                            <img
                                                className='clinicLogo borderRadius'
                                                src={item.clinicLogo}
                                                alt="Clinic Logo"
                                            />
                                        </figure>
                                        <div className="width_30" align='left' >
                                            <div className="mb-4">
                                                <div className='font_weight fontS'>{item.clinicName}
                                                    <div className="icon-location fontSize color">
                                                        {item.address}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="width_15" >
                                            <Link
                                                to="#"
                                                onClick={() => sessionShow(item._id)}
                                                className="patientlistlink">
                                                {<AccessTimeRoundedIcon
                                                    style={{ fontSize: 30 }} />}
                                            </Link>
                                        </div>
                                        {!newDoctorId ?
                                            <>
                                                <div className="mt-2">
                                                    <Link
                                                        to="#"
                                                        onClick={() => handleDeleteShow(item)}
                                                        className="editbutton">
                                                        <i className="icon-trash-2"
                                                            style={{ fontSize: 25 }}
                                                            title="Delete profile">
                                                        </i>
                                                    </Link>
                                                </div>

                                                {
                                                    item.ownerId === doctorId ?
                                                        <div className="mt-2">
                                                            <Link
                                                                onClick={() => handleClinicShow(item._id)}
                                                                to="#"
                                                                align='right'
                                                                className="editbutton">
                                                                <i className=" icon_pencil-edit"
                                                                    style={{ fontSize: 25 }}
                                                                    title="Edit profile">
                                                                </i>
                                                            </Link>
                                                        </div>
                                                        : null
                                                }
                                            </> : null}
                                    </div>
                                </div>
                            ))}
                            <div className="marginleft">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination "
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </div>
                            <Modal show={activeModal} onHide={sessionClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Set Session</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <SetSession
                                        doctorId={newDoctorId ? newDoctorId : doctorId}
                                        // newDoctorId={newDoctorId}
                                        clinicId={updateClinicId}
                                        onSubmit={sessionClose}
                                    />
                                </Modal.Body>
                            </Modal>
                            <Modal show={showClinic} onHide={handleClinicClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit Clinic</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <EditClinic
                                        doctorId={newDoctorId ? newDoctorId : doctorId}
                                        clinicId={updateClinicId}
                                        onSubmit={handleClinicClose}
                                    />
                                </Modal.Body>
                            </Modal>
                        </> :
                        <div className="clinicHistory font_weight" >Add your clinics</div>
                    }
                    <div className="row float-right">
                        <Toaster />
                    </div>
                </>
            }
            {!newDoctorId ?
                <div className="row justify-end">
                    <div className="my-2 ">
                        <Link onClick={() => handleSearchShow()}>
                            <MainButtonInput >ADD CLINIC</MainButtonInput>
                        </Link>
                    </div>
                    <div className="m-2">
                        <SecondaryButtonInput onClick={props.data}>Next</SecondaryButtonInput>
                    </div>
                </div>
                : null}
            <div>
                <Modal show={showDelete} onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert modalColor">
                            You want to delete this Clinic.
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className='appColor' onClick={() => deleteclinic(Item)}>
                            Yes
                        </Button>
                        <Button variant="default" className="borderStyle" onClick={handleDeleteClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>



        </div >
    )
}
export { AddDoctorClinicInfo }