import React, { useState, useEffect } from "react";
import { setDoctorClinic } from "../../../../recoil/atom/setDoctorClinic";
import { useRecoilState } from "recoil";
import { MainButtonInput } from "../../../../mainComponent/mainButtonInput";
import { MainInput } from "../../../../mainComponent/mainInput";
import ClinicApi from "../../../../services/ClinicApi";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { API } from "../../../../config";

const EditClinic = (props) => {
    const { doctorId, clinicId } = props;
    console.log("-------------", clinicId)
    const [selectedService, setSelectedService] = useState([]);
    const [clinicInfo, setClinicInfo] = useState([]);
    const [clinicList, setClinicList] = useRecoilState(setDoctorClinic);
    const [servicess, setServicess] = useState([])
    const { getServicess, getClinicDataById } = ClinicApi()
    const [file, setFile] = useState(null);
    useEffect(() => {
        getClinic()
        fetchServicess()
    }, [])

    const fetchServicess = () => {
        getServicess()
            .then((res) => {
                setServicess(res)
            })
    }

    const getClinic = () => {
        getClinicDataById({ clinicId })
            .then((res) => {
                setClinicInfo(res)
            })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setClinicInfo({ ...clinicInfo, [name]: value });
    };

    const handleService = (e, selectedValue) => {
        e.preventDefault()
        setSelectedService(selectedValue)
    }

    const handleFileChange = (event) => {
        setClinicInfo({ ...clinicInfo, clinicLogo: URL.createObjectURL(event.target.files[0]) })
        setFile(event.target.files[0]);
    };

    async function sendClinicInfo(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('doctorId', doctorId);
        formData.append('photo', file ? file : clinicInfo.clinicLogo);
        formData.append('clinicName', clinicInfo.clinicName);
        formData.append('address', clinicInfo.address);
        formData.append('clinicNumber', clinicInfo.clinicNumber);
        formData.append('services', selectedService);
        formData.append('accountNumber', clinicInfo.accountNumber);
        formData.append('IFSCcode', clinicInfo.IFSCcode);
        try {
            const response = await axios.post(`${API}/updateclinic/${clinicId}`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            const data = clinicList.map((clinic) => {
                if (clinicId === clinic._id) {
                    return response
                }
                else {
                    return clinic
                }
            })
            setClinicList(data)
        } catch (error) {
            console.error("Error:", error);
        }
        props.onSubmit()
    }

    return (
        <div className="col-lg-12">
            <form onSubmit={sendClinicInfo}>
                <div className="text-left">
                    <label className="font_weight">Clinic Logo</label>
                    <MainInput
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                        name="clinicLogo">
                    </MainInput>
                </div>
                <div className="form-group">
                    <label className="font_weight">Clinic Name</label>
                    <MainInput
                        type="text"
                        name="clinicName"
                        value={clinicInfo.clinicName}
                        onChange={handleChange}
                        placeholder="Enter clinic name">
                    </MainInput>
                </div>

                <label className="font_weight">Location</label>
                <MainInput
                    type="text"
                    name="address"
                    value={clinicInfo.address}
                    onChange={handleChange}
                    placeholder="Enter clinic address">
                </MainInput>

                <label className="font_weight">Clinic Number</label>
                <MainInput
                    type="text"
                    name="clinicNumber"
                    onChange={handleChange}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    maxLength={10}
                    value={clinicInfo.clinicNumber}
                    placeholder="Clinic Number (+XX)">
                </MainInput>
                <div className="form-group">
                    <label className="font_weight">Account Number</label>
                    <MainInput
                        type="text"
                        name="accountNumber"
                        value={clinicInfo.accountNumber}
                        onChange={handleChange}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        maxLength={15}
                        placeholder="Account Number">
                    </MainInput>
                </div>
                <div className="form-group">
                    <label className="font_weight">IFSC Code</label>
                    <MainInput
                        type="text"
                        name="ifscCode"
                        value={clinicInfo.IFSCcode}
                        onChange={handleChange}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        maxLength={15}
                        placeholder="IFSC code">
                    </MainInput>
                </div>
                <div className='align-left '>
                    <div align='left' className="patientData mt-2 mb-2 font_weight">
                        Clinic Services
                    </div>
                    <Autocomplete
                        disablePortal={true}
                        disableClearable
                        disableCloseOnSelect
                        multiple={true}
                        className='autocompleteWidth'
                        id={servicess._id}
                        value={selectedService.name}
                        onChange={handleService}
                        getOptionLabel={(servicess) => `${servicess.name}`}
                        options={servicess}
                        renderInput={(params) =>
                            <TextField {...params}
                                label="Service" />}
                    />
                </div>
                <div className="text-center m-3">
                    <MainButtonInput />
                </div>
            </form >
        </div >
    );
};
export { EditClinic }
